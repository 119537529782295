import "./styles.scss";

import React from "react";
import classnames from "classnames";
import { useQueryParam, NumberParam } from "use-query-params";

import ArrowIcon from "icons/slider-arrow.svg";

const Pagination = ({ page, count, per_page = 10, className, setPage }) => {
  const total_pages = Math.ceil(count / per_page);

  if (total_pages === 1) {
    return null;
  }

  return (
    <div className={classnames(`pagination`, { [className]: className })}>
      <ul>
        {page > 1 && (
          <li className="pagination__item pagination__item--arrow">
            <button onClick={() => setPage(page - 1)}>
              <ArrowIcon />
            </button>
          </li>
        )}
        {page > 2 && (
          <>
            <li className="pagination__item">
              <button onClick={() => setPage(1)}>1</button>
            </li>
            <li className="pagination__item dots">&#8230;</li>
          </>
        )}

        {page > 1 && (
          <li className="pagination__item">
            <button onClick={() => setPage(page - 1)}>{page - 1}</button>
          </li>
        )}
        <li className="pagination__item selected">
          <button>{page}</button>
        </li>
        {page + 1 <= total_pages && (
          <li className="pagination__item">
            <button onClick={() => setPage(page + 1)}>{page + 1}</button>
          </li>
        )}
        {page + 2 <= total_pages && (
          <>
            <li className="pagination__item dots">&#8230;</li>
            <li className="pagination__item">
              <button onClick={() => setPage(total_pages)}>
                {total_pages}
              </button>
            </li>
          </>
        )}
        {page < total_pages && (
          <li className="pagination__item pagination__item--arrow">
            <button onClick={() => setPage(page + 1)}>
              <ArrowIcon />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;

import "./styles.scss";

import React, { useState } from "react";
import classnames from "classnames";

import Button from "components/Button";
import Pagination from "components/Pagination";
import ApartmentsSearchBar from "./components/ApartmentsSearchBar";

import priceFormat from "utils/priceFormat";
import scrollToElement from "utils/scrollToElement";
import positionSuffixRender from "utils/positionSuffixRender";

import { useApp } from "context/app";
import { useFavoriteApartments } from "context/favorite_apartments";
import { useApartmentsSearcher } from "context/apartments_searcher";
import { useCompareApartments } from "context/compare_apartments";

import HeartIcon from "icons/heart.svg";
import DownloadIcon from "icons/download.svg";
import CompareIcon from "icons/compare.svg";
import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "investment-apartments-list";

const ApartmentsList = ({ investment_id }) => {
  const { fav_apartments, addToFavorite } = useFavoriteApartments();
  const { compare_apartments, addToCompare } = useCompareApartments();
  const { state, setPage, setSort, resetFilters } = useApartmentsSearcher();
  const { setContactFormModal } = useApp();

  const apartments_list_headers = [
    {
      label:
        investment_id === "cG9zdDo0OTEz" ? "Apartament / Dom" : "Apartament",
    },
    {
      key: "surface",
      label: (
        <>
          Metraż <span>[ m²&nbsp;]</span>
        </>
      ),
      sort_option: true,
    },
    {
      key: "floor",
      label: "Piętro",
      sort_option: true,
    },
    {
      key: "rooms",
      label: "Liczba pokoi",
      sort_option: true,
    },
    {
      label: "Pobierz kartę",
    },
    {
      key: "price",
      label: (
        <>
          Cena <span>[ {priceFormat(null, "pln")?.suffix} ]</span>
        </>
      ),
      sort_option: true,
    },
    {
      key: "status",
      label: "Status",
      sort_option: true,
    },
    {
      label: "Ulubione / Porównaj",
    },
    {
      label: "Kontakt",
    },
  ];

  const apartments_items = state?.apartments?.data?.map(
    ({ id, number, surface, floor, rooms, plan, status, investment }) => ({
      id,
      number: number || "-",
      surface: surface?.toFixed(2)?.toString()?.replace(".", ",") || "-",
      floor: floor,
      rooms: rooms || "-",
      plan: plan,
      price: "-",
      status: status?.[1],
      investment: investment?.[0],
      // price: price?.toFixed(0) || "-",
    })
  );

  const sortHandler = (key) => {
    let method;
    let new_key = key;

    if (key !== state?.sort?.key) {
      method = "asc";
    } else {
      if (state?.sort?.method === "desc") {
        return setSort(null);
      } else {
        method = "desc";
      }
    }

    setSort((prev) => ({
      key: new_key,
      method,
    }));
  };

  const is_filtered =
    !!state?.surfaceMin ||
    !!state?.surfaceMax ||
    !!state?.floor ||
    !!state?.rooms;

  return (
    <div className={mainClass} id="choose-apartament">
      <div className="container">
        <ApartmentsSearchBar />
        <span className={`${mainClass}__results-info`}>
          {!!is_filtered && (
            <button
              className={`${mainClass}__results-info__reset`}
              type="button"
              onClick={resetFilters}
            >
              Wyczyść filtry
            </button>
          )}
          {/* <div>
            Wynik wyszukiwania:{" "}
            <strong>{`${state?.apartments?.meta?.count} ${positionSuffixRender(
              state?.apartments?.meta?.count
            )}`}</strong>
          </div> */}
        </span>
        {state?.apartments?.meta?.count !== 0 && (
          <>
            <div className={`${mainClass}__list__wrapper`}>
              <div className={`${mainClass}__list`}>
                <div
                  className={classnames(
                    `${mainClass}__list__row`,
                    `${mainClass}__list__row--header`
                  )}
                >
                  {apartments_list_headers?.map(
                    ({ key, label, sort_option }, index) => (
                      <div key={index} className={`${mainClass}__list__col`}>
                        {sort_option ? (
                          <button
                            className={classnames(
                              `${mainClass}__list__col__sort`,
                              {
                                [`${mainClass}__list__col__sort--${state?.sort?.method}`]:
                                  key === state?.sort?.key,
                              }
                            )}
                            onClick={() => sortHandler(key)}
                          >
                            {label} <ArrowIcon />
                          </button>
                        ) : (
                          <span>{label}</span>
                        )}
                      </div>
                    )
                  )}
                </div>
                {apartments_items?.map(
                  (
                    {
                      id,
                      number,
                      surface,
                      floor,
                      rooms,
                      plan,
                      price,
                      status,
                      investment,
                    },
                    index
                  ) => {
                    const formatted_price = priceFormat(price, "pln");

                    return (
                      <div key={index} className={`${mainClass}__list__row`}>
                        <div className={`${mainClass}__list__col`}>
                          <span>{number}</span>
                        </div>
                        <div className={`${mainClass}__list__col`}>
                          <span>{surface}</span>
                        </div>
                        <div className={`${mainClass}__list__col`}>
                          <span>{floor}</span>
                        </div>
                        <div className={`${mainClass}__list__col`}>
                          <span>{rooms}</span>
                        </div>
                        <div className={`${mainClass}__list__col`}>
                          {!!plan ? (
                            <a
                              className={`${mainClass}__list__col__download-link`}
                              href={plan}
                              target="_blank"
                              rel="noreferrer"
                              download
                            >
                              <DownloadIcon />
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className={`${mainClass}__list__col`}>
                          <span>{formatted_price?.price}</span>
                        </div>
                        <div className={`${mainClass}__list__col`}>
                          <span>{status}</span>
                        </div>
                        <div
                          className={classnames(
                            `${mainClass}__list__col`,
                            `${mainClass}__list__col--actions`
                          )}
                        >
                          <button
                            className={classnames({
                              active: fav_apartments?.includes(id),
                            })}
                            onClick={() => addToFavorite(id)}
                          >
                            <HeartIcon />
                          </button>
                          <button
                            className={classnames({
                              active: compare_apartments?.includes(id),
                            })}
                            onClick={() => addToCompare(id)}
                          >
                            <CompareIcon />
                          </button>
                        </div>
                        <div
                          className={classnames(
                            `${mainClass}__list__col`,
                            `${mainClass}__list__col--ask`
                          )}
                        >
                          <Button
                            onClick={() =>
                              setContactFormModal({
                                investment: investment?.title,
                                apartment: number,
                              })
                            }
                          >
                            Zapytaj o ofertę
                          </Button>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <Pagination
              page={state?.page}
              count={state?.apartments?.meta?.count}
              setPage={setPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ApartmentsList;

import "./styles.scss";

import React from "react";
import classnames from "classnames";

import CondoStarIcon from "icons/condo-star.svg";
import priceFormat from "../../../utils/priceFormat";

const mainClass = "investment__promotion-baner";

const PromotionBaner = ({
  promotion_price,
  regular_price,
  currency,
  promotion_price_type,
  promotion_lowest_price,
}) => {
  const formatted_promotion_price = priceFormat(
    promotion_price,
    currency,
    promotion_price_type
  );
  const formatted_regular_price = priceFormat(
    regular_price,
    currency,
    promotion_price_type
  );
  const formatted_promotion_lowest_price = priceFormat(
    promotion_lowest_price,
    currency,
    promotion_price_type
  );

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__text`}>
          <CondoStarIcon />
          <h3>Promocja!</h3>
          <span>
            tylko na
            <br />
            condo.pl
          </span>
        </div>
        <div className={`${mainClass}__price`}>
          <div
            className={classnames(
              `${mainClass}__price__item`,
              `${mainClass}__price__item--regular`
            )}
          >
            <label>Cena regularna:</label>
            <strike>
              {formatted_regular_price?.price} {formatted_regular_price?.suffix}
            </strike>
          </div>
          <div
            className={classnames(
              `${mainClass}__price__item`,
              `${mainClass}__price__item--promotion`
            )}
          >
            <label>Promocyjna cena apartamentów już od</label>
            <span>
              {formatted_promotion_price?.price}{" "}
              <span>{formatted_promotion_price?.suffix}</span>
            </span>
            {!!promotion_lowest_price && (
              <p>
                Najniższa cena z ostatnich 30 dni:{" "}
                <span>
                  <strong>{formatted_promotion_lowest_price?.price}</strong>{" "}
                  {formatted_promotion_lowest_price?.suffix}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionBaner;

import "./styles.scss";
import React from "react";

import facilitiesParser from "utils/facilitiesParser";

const mainClass = "investment-facilities";

const Facilities = ({ data }) => {
  const features_items = facilitiesParser(data);

  return (
    <div className={mainClass} id="facilities">
      <div className="container">
        <h2>Udogodnienia</h2>
        <div className={`${mainClass}__items`}>
          {features_items?.map(({ icon, label, status }, index) => {
            if (!!!status) return null;
            return (
              <div key={index} className={`${mainClass}__items__item`}>
                {icon}{" "}
                <label
                  dangerouslySetInnerHTML={{
                    __html: label.split("|").join("<br/>"),
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Facilities;

import React, { createContext, useMemo, useState, useContext } from "react";
import apartments_filters from "utils/apartments_filters";

const ApartmentsSearcherContext = createContext();

function ApartmentsSearcherProvider({ children, values }) {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(null);
  const [surfaceMin, setSurfaceMin] = useState(null);
  const [surfaceMax, setSurfaceMax] = useState(null);
  const [floor, setFloor] = useState(null);
  const [rooms, setRooms] = useState(null);

  const resetFilters = () => {
    setPage(1);
    setSurfaceMin(null);
    setSurfaceMax(null);
    setFloor(null);
    setRooms(null);
  };

  const filterred_apartments = apartments_filters({
    apartments: values?.apartments || [],
    sort,
    page,
    surfaceMin,
    surfaceMax,
    floor,
    rooms,
  });

  const state = {
    apartments: filterred_apartments,
    sort,
    page,
    surfaceMin,
    surfaceMax,
    floor,
    rooms,
    page,
  };

  const value = useMemo(() => {
    return {
      state,
      all_apartments: values?.apartments,
      setSort,
      setPage,
      setSurfaceMin,
      setSurfaceMax,
      setFloor,
      setRooms,
      resetFilters,
    };
  }, [state, values?.apartments]);

  return (
    <ApartmentsSearcherContext.Provider value={value}>
      {children}
    </ApartmentsSearcherContext.Provider>
  );
}

const useApartmentsSearcher = () => useContext(ApartmentsSearcherContext);
export { ApartmentsSearcherContext, useApartmentsSearcher };
export default ApartmentsSearcherProvider;

import { some } from "lodash";

const apartments_filters = ({
  sort,
  apartments,
  page,
  surfaceMin,
  surfaceMax,
  floor,
  rooms,
}) => {
  let filterred_apartments = apartments;

  filterred_apartments = filterred_apartments?.filter(
    (item) =>
      item?.status?.[1] !== "Sprzedane" && item?.status?.[1] !== "Zarezerwowane"
  );

  if (!!surfaceMin) {
    filterred_apartments = filterred_apartments.filter(
      (item) => item?.surface >= parseFloat(surfaceMin)
    );
  }

  if (!!surfaceMax) {
    filterred_apartments = filterred_apartments.filter(
      (item) => item?.surface <= parseFloat(surfaceMax)
    );
  }

  if (floor?.length > 0) {
    filterred_apartments = filterred_apartments.filter((item) =>
      some(floor, (_floor) => {
        return item?.floor === _floor;
      })
    );
  }

  if (rooms?.length > 0) {
    filterred_apartments = filterred_apartments.filter((item) =>
      some(rooms, (_rooms) => {
        return item?.rooms === _rooms;
      })
    );
  }

  if (!!sort) {
    if (sort?.method === "asc") {
      filterred_apartments = filterred_apartments.sort((a, b) =>
        a[sort?.key] > b[sort?.key] ? 1 : -1
      );
    } else {
      filterred_apartments = filterred_apartments.sort((a, b) =>
        a[sort?.key] > b[sort?.key] ? -1 : 1
      );
    }
  } else {
    filterred_apartments = filterred_apartments.sort((a, b) =>
      a.number > b.number ? 1 : -1
    );
  }

  const skip = (page - 1) * 9;

  let data_for_page = filterred_apartments.slice(skip, skip + 9);

  return {
    data: data_for_page,
    meta: { count: filterred_apartments?.length },
  };
};

export default apartments_filters;

import "./styles.scss";
import React from "react";
import classnames from "classnames";

import InvestmentLogo from "components/InvestmentLogo";

import { useFavoriteInvestments } from "context/favorite_investments";
import { useCompareInvestments } from "context/compare_investments";

import CompareIcon from "icons/compare.svg";
import HeartIcon from "icons/heart.svg";

const mainClass = "investment-header";

const InvestmentHeader = ({ id, image, logo, title, sub_title, label }) => {
  const { fav_investments, addToFavorite } = useFavoriteInvestments();
  const { compare_investments, addToCompare } = useCompareInvestments();

  const action_buttons = [
    {
      icon: <CompareIcon />,
      label: "Porównaj",
      onClick: () => addToCompare(id),
      active: !!compare_investments?.includes(id),
    },
    {
      icon: <HeartIcon />,
      label: "Dodaj do ulubionych",
      onClick: () => addToFavorite(id),
      active: !!fav_investments?.includes(id),
    },
  ];

  return (
    <section className={mainClass} style={{ backgroundImage: `url(${image})` }}>
      <div className="container">
        <div className={`${mainClass}__info`}>
          <InvestmentLogo image={logo} />
          <div className={`${mainClass}__info__text`}>
            <h1
              dangerouslySetInnerHTML={{
                __html: title?.split("|")?.join("<br/>"),
              }}
            />
            <span>{sub_title}</span>
          </div>
          <div className={`${mainClass}__info__actions`}>
            {action_buttons?.map(({ icon, label, onClick, active }, index) => (
              <button
                className={classnames({ active: !!active })}
                key={index}
                onClick={onClick}
              >
                {icon} {label}
              </button>
            ))}
          </div>
        </div>
        {!!label?.state && (
          <div className={`${mainClass}__label`}>
            <span>{label?.text}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default InvestmentHeader;

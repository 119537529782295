import "./styles.scss";

import React from "react";

import ItemsSlider from "components/ItemsSlider";
import InvestmentGridItem from "components/InvestmentGridItem";

import investmentTileParser from "utils/investmentTileParser";

const mainClass = "investment__more-offers";

const MoreOffers = ({ data }) => {
  const investments_data = investmentTileParser(data);

  return (
    <div className={mainClass} id="location">
      <div className="container">
        <h2>Klienci zainteresowani tą ofertą oglądali również</h2>
        <ItemsSlider>
          {investments_data.map((props, index) => (
            <div key={index}>
              <InvestmentGridItem {...props} popup_form />
            </div>
          ))}
        </ItemsSlider>
      </div>
    </div>
  );
};

export default MoreOffers;

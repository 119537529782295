import "./styles.scss";

import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";

import { useApp } from "context/app";

import MessageIcon from "icons/message.svg";

const mainClass = "investment-menu";

const Menu = ({ location, hide_apartments }) => {
  const { setContactFormModal } = useApp();

  const [active, setActive] = useState(null);
  const [section_positions, setSectionPostions] = useState([]);

  const menu_items = [
    {
      key: "gallery",
      label: "Galeria inwestycji",
    },
    {
      key: "apartments",
      label: "Opis inwestycji",
    },
    {
      key: "facilities",
      label: "Udogodnienia",
    },
    {
      key: "investor",
      label: "O inwestorze",
    },
    {
      key: "location",
      label: "Lokalizacja",
    },
    ...(!!!hide_apartments
      ? [
          {
            key: "choose-apartament",
            label: (
              <span>
                Wybierz <label>apartament</label>
              </span>
            ),
          },
        ]
      : []),
    {
      key: "contact-form",
      type: "contact",
      icon: <MessageIcon />,
      label: "Skontaktuj się z nami",
      onClick: () => setContactFormModal({ investment: location }),
    },
  ];

  const scrollToSection = (index) => {
    const element = document.getElementById(menu_items[index]?.key);

    window.scroll({
      top: element.offsetTop - 190,
      left: 0,
      behavior: "smooth",
      block: "start",
    });
  };

  const handleScroll = useCallback((e) => {
    if (e.currentTarget.scrollY < section_positions[0]) {
      setActive(0);
    } else if (
      e.currentTarget.scrollY > section_positions[section_positions?.length - 1]
    ) {
      setActive(section_positions?.length - 1);
    } else {
      const find_index = section_positions?.findIndex(
        (item, index) =>
          e.currentTarget.scrollY > item - 200 &&
          e.currentTarget.scrollY < section_positions[index + 1] - 200
      );

      setActive(find_index);
    }
  });

  useEffect(() => {
    if (section_positions.length === 0) {
      const positions = menu_items?.map(
        ({ key }) => document.getElementById(key)?.offsetTop
      );
      setSectionPostions(positions);
    }

    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__items`}>
          {menu_items?.map(({ key, icon, label, onClick }, index) => (
            <button
              key={index}
              onClick={() => (!!onClick ? onClick() : scrollToSection(index))}
              className={classnames(`${mainClass}__items__item`, {
                [`${mainClass}__items__item--active`]: active === index,
                [`${mainClass}__items__item--with-icon`]: !!icon,
                [`${mainClass}__items__item--choose-apartament`]:
                  key === "choose-apartament",
              })}
            >
              {icon}
              <span>{label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;

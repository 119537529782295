/* eslint-disable no-undef */
/* global google */

import React, { useState, useRef, useEffect } from "react";
import { Link, withPrefix } from "gatsby";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import priceFormat from "utils/priceFormat";

const mapOptions = {
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        {
          hue: "#008eff",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: "0",
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          saturation: "-60",
        },
        {
          lightness: "-20",
        },
      ],
    },
  ],
};

const LocationMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB9H1a73VE68-mNjSArqo8hJRUPes2LuR0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div className="map-location" style={{ height: `450px` }} />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ markers }) => {
  const ref = useRef();

  const [selected_marker, setSelectedMarker] = useState(null);
  const selected_market_price = priceFormat(
    markers[selected_marker]?.investments?.priceMin,
    markers[selected_marker]?.investments?.currency
  );

  useEffect(() => {
    if (markers.length === 1) {
      setSelectedMarker(0);
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    for (let loc of markers)
      bounds.extend({
        lat: parseFloat(loc.investments.locationLat),
        lng: parseFloat(loc.investments.locationLng),
      });

    ref.current.fitBounds(bounds);
  }, [ref]);

  var icon = {
    url: withPrefix("/map-marker.png"),
    scaledSize: new google.maps.Size(30, 42),
  };

  return (
    <GoogleMap
      ref={ref}
      defaultZoom={12}
      defaultCenter={{
        lat: parseFloat(markers[0]?.investments?.locationLat),
        lng: parseFloat(markers[0]?.investments?.locationLng),
      }}
      defaultOptions={{
        styles: mapOptions.styles,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
      }}
    >
      {markers?.map((node, index) => {
        const investmentLat = parseFloat(node.investments.locationLat);
        const investmentLng = parseFloat(node.investments.locationLng);

        if (!!!investmentLat || !!!investmentLng) return null;

        return (
          <Marker
            icon={icon}
            position={{ lat: investmentLat, lng: investmentLng }}
            key={index}
            onMouseOver={() => setSelectedMarker(index)}
            onMouseLeave={() => setSelectedMarker(null)}
            onClick={() => setSelectedMarker(index)}
          />
        );
      })}
      {selected_marker !== null && (
        <InfoWindow
          position={{
            lat: parseFloat(markers[selected_marker]?.investments?.locationLat),
            lng: parseFloat(markers[selected_marker]?.investments?.locationLng),
          }}
        >
          <div className="window-wrapper">
            <button
              type="button"
              className="investment-details-map__close-btn"
              onClick={() => setSelectedMarker(null)}
            >
              &#10005;
            </button>
            {!!markers[selected_marker]?.uri ? (
              <Link to={markers[selected_marker]?.uri}>
                <div className="investment-details-map">
                  <div
                    className="investment-details-map__img"
                    style={{
                      backgroundImage: `url("${markers[selected_marker]?.investments?.mainImg?.sourceUrl}")`,
                    }}
                  />
                  <div
                    className="investment-details-map__name"
                    dangerouslySetInnerHTML={{
                      __html: markers[selected_marker]?.investments?.mainTitle
                        ?.split("|")
                        ?.join("<br/>"),
                    }}
                  />
                  <div className="investment-details-map__location">
                    {markers[selected_marker]?.investments?.shortLocation}
                  </div>
                  {!!selected_market_price?.price && (
                    <div className="investment-details-map__price">
                      Od{" "}
                      {`${selected_market_price?.price} ${selected_market_price?.suffix}`}
                    </div>
                  )}
                </div>
              </Link>
            ) : (
              <div className="investment-details-map">
                <div
                  className="investment-details-map__img"
                  style={{
                    backgroundImage: `url("${markers[selected_marker]?.investments?.mainImg?.sourceUrl}")`,
                  }}
                />
                <div
                  className="investment-details-map__name"
                  dangerouslySetInnerHTML={{
                    __html: markers[selected_marker]?.investments?.mainTitle
                      ?.split("|")
                      ?.join("<br/>"),
                  }}
                />
                <div className="investment-details-map__location">
                  {markers[selected_marker]?.investments?.shortLocation}
                </div>

                <div className="investment-details-map__price">
                  {!!selected_market_price?.price
                    ? `Od ${selected_market_price?.price} ${selected_market_price?.suffix}`
                    : "Brak ceny"}
                </div>
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
});

export default LocationMap;

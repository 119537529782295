import "./styles.scss";

import React from "react";
import classnames from "classnames";

import priceFormat from "../../../utils/priceFormat";

const mainClass = "investment-info-tiles";

const vacation_days_variant = (value) => {
  switch (value) {
    case "none":
      return "Brak";
    case "other":
      return "Inne";
    default:
      return value;
  }
};

const InfoTiles = ({
  price,
  currency,
  back_percentage,
  subdivision_model,
  contract_length,
  deadline,
  surface,
  vacation_days,
}) => {
  const formatted_price = priceFormat(price, currency);

  const info_tiles_items = [
    {
      label: "Cena od",
      value: formatted_price?.price,
      suffix: formatted_price?.suffix,
    },
    {
      label: "Stopa zwrotu",
      value: !!subdivision_model ? "Model podziałowy" : back_percentage,
      suffix: !!subdivision_model ? null : "%",
      variant: !!subdivision_model ? "small" : null,
    },
    {
      label: "Długość umowy",
      value: contract_length,
      suffix: "lat",
    },
    {
      label: "Termin oddania",
      value: deadline,
    },
    {
      label: "Powierzchnia",
      value: surface,
      suffix: (
        <>
          m<sup>2</sup>
        </>
      ),
    },
    {
      label: "Pobyt właścicielski",
      value: vacation_days,
      // suffix:
      //   vacation_days !== "none" && vacation_days !== "other" ? "dni" : null,
    },
  ];

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__items`}>
          {info_tiles_items?.map(({ label, value, suffix, variant }, index) => {
            if (!!!value) return;
            return (
              <div
                key={index}
                className={classnames(`${mainClass}__items__item`, {
                  [`${mainClass}__items__item--${variant}`]: !!variant,
                })}
              >
                <label>{label}</label>
                <span>
                  {value} <span>{suffix}</span>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InfoTiles;

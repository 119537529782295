import "./styles.scss";

import React from "react";
import classnames from "classnames";

const mainClass = "investment-logo";

const InvestmentLogo = ({ image, small }) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--small`]: !!small,
      })}
    >
      <img src={image} alt="" />
    </div>
  );
};

export default InvestmentLogo;

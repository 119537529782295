import "./styles.scss";

import React from "react";
import Slider from "react-slick";
import classnames from "classnames";

import LocationMap from "./LocationMap";

import CondoPinIcon from "icons/condo-pin.svg";
import CarIcon from "icons/car.svg";
import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "investment-location";

const location_slider_items = [
  {
    label: "Warszawa",
    value: 392,
  },
  {
    label: "Kraków",
    value: 129,
  },
  {
    label: "Łódź",
    value: 364,
  },
  {
    label: "Katowice",
    value: 220,
  },
  {
    label: "Warszawa",
    value: 392,
  },
  {
    label: "Kraków",
    value: 129,
  },
  {
    label: "Łódź",
    value: 364,
  },
  {
    label: "Katowice",
    value: 220,
  },
  {
    label: "Warszawa",
    value: 392,
  },
  {
    label: "Kraków",
    value: 129,
  },
  {
    label: "Łódź",
    value: 364,
  },
  {
    label: "Katowice",
    value: 220,
  },
  {
    label: "Warszawa",
    value: 392,
  },
  {
    label: "Kraków",
    value: 129,
  },
  {
    label: "Łódź",
    value: 364,
  },
  {
    label: "Katowice",
    value: 220,
  },
];

function CustomArrow(props) {
  const { className, onClick } = props;
  return <ArrowIcon className={className} onClick={onClick} />;
}

const Location = ({ data }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 8000,
    useTransform: false,
    nextArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--next" />
    ),
    prevArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--prev" />
    ),
  };

  return (
    <div className={mainClass} id="location">
      <div className="container">
        <div className={`${mainClass}__header`}>
          <CondoPinIcon />
          <h2>Lokalizacja condohotelu</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data?.locationDesc }} />
        <LocationMap markers={[{ investments: { ...data } }]} small />
      </div>
    </div>
  );
};

export default Location;

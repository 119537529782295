import "styles/pages/home-page.scss";

import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";

import {
  InvestmentHeader,
  InfoTiles,
  Menu,
  Apartments,
  Investor,
  Location,
  Gallery,
  Facilities,
  PromotionBaner,
  MoreOffers,
} from "page_components/investment";
import ApartmentsSearcherProvider from "../context/apartments_searcher";
import ApartmentsList from "../page_components/investment/ApartmentsList";

const Investment = ({ pageContext, data: { allInvestments } }) => {
  const { data } = pageContext;
  const investment = data.investments;
  let apartments = data.apartments?.nodes;

  apartments = apartments
    .filter((item) => item?.apartments?.investment?.[0]?.id === data?.id)
    ?.map((item) => ({
      id: item?.id,
      ...item?.apartments,
      floor: item?.apartments?.floor || 0,
    }));

  const seo = data?.seo;
  const schema = JSON.parse(pageContext.data.seo.schema.raw);

  const extra_investments = allInvestments?.nodes?.filter(
    ({ investments: { category } }) =>
      category.includes(investment?.category?.[0])
  );

  const extra_investments2 = allInvestments?.nodes?.filter(
    ({ investments: { category } }) =>
      category.includes(investment?.category?.[1])
  );

  const extra_combined = [...extra_investments, ...extra_investments2]
    .filter(({ id }) => id !== pageContext?.id)
    .sort(() => 0.5 - Math.random())
    .slice(0, 3);

  return (
    <Layout
      seo={{ title: seo?.title, desc: seo?.desc, schema }}
      location={investment?.mainTitle}
    >
      <InvestmentHeader
        id={data?.id}
        image={investment?.mainImg?.sourceUrl}
        logo={investment?.investLogo?.sourceUrl}
        title={investment?.mainTitle}
        sub_title={investment?.subtitle}
        label={{
          state: investment?.labelState,
          text: investment?.labelText,
        }}
      />
      {!!investment?.promotion && !!investment?.promotionPrice && (
        <PromotionBaner
          promotion_price={investment?.promotionPrice?.toFixed(0)}
          promotion_price_type={investment?.promotionPriceType}
          regular_price={investment?.promotionRegularPrice}
          promotion_lowest_price={investment?.promotionLowestPrice}
          currency={investment?.currency}
        />
      )}
      <Gallery data={investment?.gallery} />
      <InfoTiles
        price={investment?.priceMin}
        currency={investment?.currency}
        back_percentage={investment?.backPercentage}
        contract_length={investment?.contactLength}
        deadline={investment?.deadline}
        surface={`${investment?.surfacemin
          ?.toString()
          ?.replace(".", ",")} - ${investment?.surfacemax
          ?.toString()
          ?.replace(".", ",")}`}
        subdivision_model={investment?.subdivisionModel}
        vacation_days={investment?.vacationsDaysCustom}
      />
      <Menu
        location={investment?.mainTitle}
        hide_apartments={apartments?.length === 0}
      />
      <Apartments
        investment_id={data?.id}
        data={investment}
        featured_image={investment?.gallery?.[0]}
      />
      <Facilities data={investment} />
      <Investor data={investment?.aboutInvestorDesc} />
      <Location data={investment} />
      {apartments?.length > 0 && (
        <ApartmentsSearcherProvider values={{ apartments }}>
          <ApartmentsList investment_id={data?.id} />
        </ApartmentsSearcherProvider>
      )}
      <MoreOffers data={extra_combined} />
    </Layout>
  );
};

export default Investment;

export const query = graphql`
  query moreInvestments {
    allInvestments: allWpInvestment(
      sort: { fields: investments___ready, order: DESC }
      filter: {
        investments: { hideInvestment: { eq: true }, ready: { eq: null } }
      }
    ) {
      nodes {
        title
        id
        uri
        slug
        investments {
          category
          homePick
          backPercentage
          contactLength
          deadline
          investLogo {
            sourceUrl
            altText
          }
          mainImg {
            sourceUrl
          }
          mainTitle
          priceMin
          currency
          subtitle
          shortLocation
          surfacemin
          vacationsDaysCustom
          labelState
          labelText
        }
      }
    }
  }
`;

import "./styles.scss";

import React from "react";
import classnames from "classnames";
import { Field, Form, Formik } from "formik";

import Input from "components/form/Input";
import ReactSelect from "components/ReactSelect/ReactSelect";

import { useApartmentsSearcher } from "context/apartments_searcher";

const mainClass = "apartments-search-bar";

const ApartmentsSearchBar = ({ variant }) => {
  const {
    state,
    all_apartments,
    setPage,
    setSurfaceMin,
    setSurfaceMax,
    setFloor,
    setRooms,
  } = useApartmentsSearcher();

  const onSubmit = (values) => {
    setSurfaceMin(values.surfaceMin);
    setSurfaceMax(values.surfaceMax);
    setFloor(values?.floor);
    setRooms(values?.rooms);
    setPage(1);
  };

  const floor_options = all_apartments
    ?.map((item) => item?.floor)
    .filter(function (value, index, array) {
      return array.indexOf(value) === index;
    })
    ?.filter((item) => item !== null)
    ?.sort();

  const rooms_options = all_apartments
    ?.map((item) => item?.rooms)
    .filter(function (value, index, array) {
      return array.indexOf(value) === index;
    })
    ?.filter((item) => item !== null)
    ?.sort();

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${variant}`]: !!variant,
      })}
    >
      <h2>Wybierz apartament z dostępnej oferty</h2>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={{
          page: state?.page || "",
          surfaceMin: state?.surfaceMin || "",
          surfaceMax: state?.surfaceMax || "",
          floor: state?.floor || [],
          rooms: state?.rooms || [],
        }}
      >
        {() => (
          <Form id="wyszukiwarka-form">
            <div className={`${mainClass}__form`}>
              <div className={`${mainClass}__form__standard-options`}>
                <Field
                  component={Input}
                  placeholder="Metraż od"
                  type="text"
                  name="surfaceMin"
                  sufix="m²"
                  className="test"
                  color="orange"
                />
                <Field
                  component={Input}
                  placeholder="Metraż do"
                  type="text"
                  name="surfaceMax"
                  sufix="m²"
                  color="orange"
                />
                <Field
                  placeholder="Piętro"
                  name="floor"
                  component={ReactSelect}
                  isMulti
                  options={floor_options?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
                <Field
                  placeholder="Liczba pokoi"
                  name="rooms"
                  component={ReactSelect}
                  isMulti
                  options={rooms_options?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
                <button type="submit">Szukaj</button>
              </div>
              <button type="submit" className="mobile-btn">
                Szukaj
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ApartmentsSearchBar;

import "./styles.scss";
import "simplelightbox/dist/simple-lightbox.min.css";

import React, { useEffect } from "react";
import Slider from "react-slick";
import SimpleLightbox from "simplelightbox";

import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "investment-gallery";

function CustomArrow(props) {
  const { className, onClick } = props;
  return <ArrowIcon className={className} onClick={onClick} />;
}

const Gallery = ({ data }) => {
  const sliderSettings = {
    dots: true,
    infinite: data?.length > 3 ? true : false,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 8000,
    useTransform: false,
    nextArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--next" />
    ),
    prevArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--prev" />
    ),
    responsive: [
      {
        breakpoint: 810,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    new SimpleLightbox(".investment-gallery__image", {
      spinner: true,
      showCounter: false,
      disableScroll: false,
    });
  }, []);

  return (
    <div className={mainClass} id="gallery">
      <div className="container" style={data?.length > 3 ? {} : { padding: 0 }}>
        <Slider {...sliderSettings}>
          {data?.map(({ altText, sourceUrl }, index) => (
            <div key={index}>
              <a href={sourceUrl} className={`${mainClass}__image`}>
                <img src={sourceUrl} alt={altText} />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Gallery;

import "./styles.scss";
import React from "react";

import BookIcon from "icons/book.svg";
import SecureIcon from "icons/secure.svg";
import LampIcon from "icons/lamp.svg";

const mainClass = "investment-apartments";

const Apartments = ({ data, featured_image }) => {
  const features_items = [
    {
      icon: <BookIcon />,
      label: "Lokal z własną | księgą wieczystą",
      status: !!data?.smallInfoBook,
    },
    {
      icon: <SecureIcon />,
      label: "Ochrona | antyinflacyjna",
      status: !!data?.smallInfoProtect,
    },
    {
      icon: <LampIcon />,
      label: "Wykończenie i wyposażenie | w cenie",
      status: !!data?.smallInfoInterior,
    },
  ];

  return (
    <div className={mainClass} id="apartments">
      <div className="container">
        <div className={`${mainClass}__features__items`}>
          {features_items?.map(({ icon, label, status }, index) => {
            if (!!!status) return null;
            return (
              <div
                key={index}
                className={`${mainClass}__features__items__item`}
              >
                {icon}{" "}
                <label
                  dangerouslySetInnerHTML={{
                    __html: label.split("|").join("<br/>"),
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className={`${mainClass}__info`}>
          <h2>Apartamenty na sprzedaż - opis inwestycji</h2>
          <div className={`${mainClass}__info__text`}>
            <div className={`${mainClass}__info__text__image`}>
              <img src={featured_image?.sourceUrl} alt="" />
            </div>
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apartments;

import "./styles.scss";
import React from "react";

const mainClass = "investment-investor";

const Investor = ({ data }) => {
  if (!!!data) return null;

  return (
    <div className={mainClass} id="investor">
      <div className="container">
        <h2>O inwestorze</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      </div>
    </div>
  );
};

export default Investor;
